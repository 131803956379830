.social li{
    display: inline-block;
    padding: 10px 20px  
}

.social ul{
    padding: 0;
}

.twitter-icon a, .instagram-icon a, .mail-icon a, .linkedin-icon a, .telegram-icon a, .whatsapp-icon a{
    color: #eee !important;
}

.twitter-icon a:hover {
    color: #55acef !important;
}
.instagram-icon a:hover {
    color: #000 !important;
}
.mail-icon a:hover {
    color: #d93025 !important;
}
.linkedin-icon a:hover {
    color: #0077b5 !important;
}
.telegram-icon a:hover {
    color: #179cde !important;
}
.whatsapp-icon a:hover {
    color: #00e676 !important;
}